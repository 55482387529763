import httpClient from './client'

const COMMON_END_POINT = 'common'
const LOCATIONS_END_POINT = 'locations'
const EMPLOYEE_CATEGORIES_END_POINT = 'employee-categories'

const ITEM_CATEGORIES_END_POINT = 'item-categories'
const ITEM_TAGS_END_POINT = 'item-tags'
const ITEM_GROUPS_END_POINT = 'item-groups'
const ITEM_UOM_END_POINT = 'item-uom'
const ITEM_SUPPLIERS_END_POINT = 'item-suppliers'
const ITEM_RECEIVING_END_POINT = 'item-receiving'
const ITEM_STOCK_TRANSFER_END_POINT = 'stock-transfer-items'

const COMPANIES_END_POINT = 'companies'
const SUPPLIERS_END_POINT = 'suppliers'
const SUPPLIER_DETAILS_END_POINT = 'details'
const COMPANY_DETAILS_END_POINT = 'details'
const COMPANY_END_POINT = 'company'
const QUOTATION_ITEMS_END_POINT = 'quotation-items'
const QUOTATION_ITEM_KITS_END_POINT = 'quotation-item-kits'
const PURCHASE_ORDER_ITEMS_END_POINT = 'purchase-order-items'
const PURCHASE_ORDER_DRAFT_ITEMS_END_POINT = 'purchase-order-draft-items'
const COURIERS_END_POINT = 'couriers'
const TAXES_END_POINT = 'taxes'
const EMPLOYEES_END_POINT = 'employees'
const PAYMENT_METHOD_END_POINT = 'payment-methods'
const APPROVAL_MODULE_END_POINT = 'approval-modules'
const APPROVAL_CATEGORIES_END_POINT = 'approval-categories'
const INTEGRATION_ERROR_LOG_END_POINT = 'integration-errors'
const PRICE_GROUPS_END_POINT = 'price-groups'
const SCAN_TYPES_END_POINT = 'scan-types'
const FULFILLMENT_STATUS_TYPES_END_POINT = 'fulfillment-status-types'
const LOCATION_END_POINT = 'location'
const LOCATION_DETAILS_END_POINT = 'details'
const ECOMMERCE_STOCK_UPDATE_SCHEDULE_END_POINT = 'ecommerce-stock-update-schedule'

const ECOMMERCE_ACCOUNT_TYPES ='ecommerce-accounts-types'
const GET_USER_NOTIFICATIONS ='user-notification-get'
const GET_USER_NEW_NOTIFICATIONS_COUNT ='user-notification-count'
const RESET_USER_NEW_NOTIFICATIONS_COUNT ='user-notification-reset'

const REASON_END_POINT = 'reasons'

const getAllLocations = (qs) => httpClient.get(COMMON_END_POINT + '/' + LOCATIONS_END_POINT + qs);
const getEmployeeCategories = (qs) => httpClient.get(COMMON_END_POINT + '/' + EMPLOYEE_CATEGORIES_END_POINT + qs);
const getItemCategories = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_CATEGORIES_END_POINT + qs);
const getItemTags = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_TAGS_END_POINT + qs);
const getItemGroups = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_GROUPS_END_POINT + qs);
const getItemSuppliers = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_SUPPLIERS_END_POINT + qs);
const getItemsForReceiving = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_RECEIVING_END_POINT + qs);
const getItemsForStockTransfer = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_STOCK_TRANSFER_END_POINT + qs);
const getAllCompanies = (qs) => httpClient.get(COMMON_END_POINT + '/' + COMPANIES_END_POINT + qs);
const getCompanyDetails = (companyId) => httpClient.get(COMMON_END_POINT + '/' + COMPANY_END_POINT + '/' + companyId + '/' + COMPANY_DETAILS_END_POINT);
const getAllQuotationItems = (qs) => httpClient.get(COMMON_END_POINT + '/' + QUOTATION_ITEMS_END_POINT + qs);
const getAllQuotationItemKits = (qs) => httpClient.get(COMMON_END_POINT + '/' + QUOTATION_ITEM_KITS_END_POINT + qs);
const getAllPurchaseOrderItems = (qs) => httpClient.get(COMMON_END_POINT + '/' + PURCHASE_ORDER_ITEMS_END_POINT + qs);

const getAllPurchaseOrderDraftItems = (qs) => httpClient.get(COMMON_END_POINT + '/' + PURCHASE_ORDER_DRAFT_ITEMS_END_POINT + qs);
const getCouriers = (qs) => httpClient.get(COMMON_END_POINT + '/' + COURIERS_END_POINT + qs);
const getTaxes = (qs) => httpClient.get(COMMON_END_POINT + '/' + TAXES_END_POINT + qs);
const getEmployees = (qs) => httpClient.get(COMMON_END_POINT + '/' + EMPLOYEES_END_POINT + qs);
const getAllSuppliers = (qs) => httpClient.get(COMMON_END_POINT + '/' + SUPPLIERS_END_POINT + qs);
const getSupperDetails = (supplierId) => httpClient.get(COMMON_END_POINT + '/' + SUPPLIERS_END_POINT + '/' + supplierId + '/' + SUPPLIER_DETAILS_END_POINT);
const getItemUOM = (qs) => httpClient.get(COMMON_END_POINT + '/' + ITEM_UOM_END_POINT + qs)
const getPaymentMethod = (qs) => httpClient.get(COMMON_END_POINT + '/' + PAYMENT_METHOD_END_POINT + qs)
const getApprovalModule = (qs) => httpClient.get(COMMON_END_POINT + '/' + APPROVAL_MODULE_END_POINT + qs)
const getApprovalCategories = (qs) => httpClient.get(COMMON_END_POINT + '/' + APPROVAL_CATEGORIES_END_POINT + qs)
const getIntegrationErrorLog = (qs) => httpClient.get(COMMON_END_POINT + '/' + INTEGRATION_ERROR_LOG_END_POINT + qs)
const getPriceGroups = () => httpClient.get(COMMON_END_POINT + '/' + PRICE_GROUPS_END_POINT)
const getScanTypes = (qs) => httpClient.get(COMMON_END_POINT + '/' + SCAN_TYPES_END_POINT + qs);
const getFulfillmentStatus = (qs) => httpClient.get(COMMON_END_POINT + '/' + FULFILLMENT_STATUS_TYPES_END_POINT + qs);
const getLocationDetails = (qs, locationId) => httpClient.get(COMMON_END_POINT + '/' + LOCATION_END_POINT + '/' + locationId + '/' + LOCATION_DETAILS_END_POINT + qs);

const getStockUpdateSchedule = (qs) => httpClient.get(COMMON_END_POINT + '/' + ECOMMERCE_STOCK_UPDATE_SCHEDULE_END_POINT + qs);

const getEcommerceAccountTypes = (qs) => httpClient.get(COMMON_END_POINT + '/' + ECOMMERCE_ACCOUNT_TYPES + qs);
const getUserNotifications = (qs) => httpClient.get(COMMON_END_POINT + '/' + GET_USER_NOTIFICATIONS + qs);
const getUserNotificationsCount = (qs) => httpClient.get(COMMON_END_POINT + '/' + GET_USER_NEW_NOTIFICATIONS_COUNT + qs);
const ResetUserNotificationCount = (qs) => httpClient.get(COMMON_END_POINT + '/' + RESET_USER_NEW_NOTIFICATIONS_COUNT + qs);

const getReasons = (qs) => httpClient.get(COMMON_END_POINT + '/' + REASON_END_POINT + qs);

export {
    getAllLocations,
    getEmployeeCategories,
    getItemCategories,
    getItemTags,
    getItemGroups,
    getItemSuppliers,
    getItemsForReceiving,
    getItemsForStockTransfer,
    getAllCompanies,
    getCompanyDetails,
    getAllQuotationItems,
    getAllQuotationItemKits,
    getCouriers,
    getTaxes,
    getEmployees,
    getAllSuppliers,
    getSupperDetails,
    getItemUOM,
    getAllPurchaseOrderItems,
    getPaymentMethod,
    getIntegrationErrorLog,
    getPriceGroups,
    getApprovalModule,
    getApprovalCategories,
    getScanTypes,
    getFulfillmentStatus,
    getLocationDetails,
    getStockUpdateSchedule,
    getEcommerceAccountTypes,
    getUserNotifications,
    getUserNotificationsCount,
    ResetUserNotificationCount,
    getAllPurchaseOrderDraftItems,
    getReasons
}
